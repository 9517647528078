import { createContext } from 'react';

import type { Socket } from 'phoenix';
import type { Dispatch, SetStateAction } from 'react';
import type { FeatureFlag, RailState } from 'types';

type GlobalContextTypes = {
  announceLiveMessage: (message?: string) => void;
  breakPoints:
    | {
        screenWidth: number;
        screenHeight: number;
        isScreenExtraSmall: boolean;
        isScreenSmall: boolean;
        isScreenMedium: boolean;
        isScreenLarge: boolean;
        isScreenExtraLarge: boolean;
      }
    | Record<string, never>;
  choseCreate: boolean;
  choseJoin: boolean;
  closedCreate: boolean;
  closedIntentModal: boolean;
  closedJoin: boolean;
  featureFlags: FeatureFlag[];
  isProduction: boolean;
  openPopover: boolean;
  queuedTourNewGroup: boolean;
  queuedTourNewTopic: boolean;
  rail?: RailState;
  setChoseCreate: (choice: boolean) => void;
  setChoseJoin: (choice: boolean) => void;
  setClosedCreate: (choice: boolean) => void;
  setClosedIntentModal: (choice: boolean) => void;
  setClosedJoin: (choice: boolean) => void;
  setHideHelp: Dispatch<SetStateAction<boolean>>;
  setOpenPopover: (choice: boolean) => void;
  setQueuedTourNewGroup: (queuedTourNewGroup: boolean) => void;
  setQueuedTourNewTopic: (queuedTourNewTopic: boolean) => void;
  setRail: Dispatch<SetStateAction<RailState | undefined>>;
  socket?: Socket;
};

const initialGlobalContextValues = {
  announceLiveMessage: () => {},
  breakPoints: {},
  choseCreate: false,
  choseJoin: false,
  closedCreate: false,
  closedIntentModal: false,
  closedJoin: false,
  featureFlags: [],
  isProduction: false,
  openPopover: false,
  queuedTourNewGroup: false,
  queuedTourNewTopic: false,
  rail: '',
  setChoseCreate: () => {},
  setChoseJoin: () => {},
  setClosedCreate: () => {},
  setClosedIntentModal: () => {},
  setClosedJoin: () => {},
  setHideHelp: () => {},
  setOpenPopover: () => {},
  setQueuedTourNewGroup: () => {},
  setQueuedTourNewTopic: () => {},
  setRail: () => {},
};

export default createContext(initialGlobalContextValues as GlobalContextTypes);
